import React from  'react';

// components
import SimpleSlider from '../../components/hero';
import Story from '../../components/story';
import Location from '../../components/location';
import Rsvp from '../../components/rsvp';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar';
import Saveday from '../../components/countdown';
import ImgSlider from '../../components/ImgSlider';
import { useState, useEffect } from 'react';

const Homepage = () => {
    // State to track the sequence of keypresses
  console.log("Qui es la persona amb mes johns?")
  const [keySequence, setKeySequence] = useState('');

  // The target string to trigger the alert
  const targetString = "poljohns";

  useEffect(() => {
    const handleKeyPress = (event) => {
      // Update the keySequence state with the new sequence of pressed keys
      // Keep only the last 'targetString.length' characters to match against the target string
      setKeySequence((prevSequence) => (prevSequence + event.key).slice(-targetString.length));
    };

    // Add keypress event listener
    window.addEventListener('keypress', handleKeyPress);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  // Effect hook to check if the current key sequence matches the target string
  useEffect(() => {
    if (keySequence === targetString) {
      alert(`${targetString}`);
      setKeySequence(''); // Reset the key sequence
    }
  }, [keySequence]); // This effect runs whenever keySequence changes


    return(
       <div>
           <Navbar/>
           <SimpleSlider/>
           <Saveday/>
           <ImgSlider/>
           <Story/>
           <Location/>
           <Rsvp/>
           <Gift/>
       </div>
    )
}

export default Homepage;