import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

class SimpleSlider extends Component {
    render() {
      return (
          <div id="home" className="item1">
              <div className="container">
                  <div className="slide-content">
                  </div>
              </div>
          </div>
      );
    }
  }

export default SimpleSlider;