import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import img1 from './imgs/001.JPG';
import img2 from './imgs/002.JPG';
import img3 from './imgs/003.jpg';
import img4 from './imgs/004.jpg';
import img5 from './imgs/005.jpg';
import img7 from './imgs/007.jpg';
import img8 from './imgs/008.jpg';
import img9 from './imgs/009.JPG';
import img10 from './imgs/010.jpg';
import img11 from './imgs/011.jpg';
import img12 from './imgs/012.jpg';
import img13 from './imgs/013.jpg';
import img14 from './imgs/014.jpg';
import img15 from './imgs/015.jpg';
import img16 from './imgs/016.jpg';
import img17 from './imgs/017.jpg';
import img18 from './imgs/018.jpg';
import img19 from './imgs/019.jpg';
import img20 from './imgs/020.jpg';
import img21 from './imgs/021.jpg';
import img22 from './imgs/022.jpg';
import img23 from './imgs/023.jpg';
import img24 from './imgs/024.jpg';
import img25 from './imgs/025.jpg';
import img26 from './imgs/026.jpg';
import img27 from './imgs/027.jpg';
import img28 from './imgs/028.jpg';
import img29 from './imgs/029.jpg';
import img30 from './imgs/030.jpg';
import img31 from './imgs/031.jpg';
import img32 from './imgs/032.jpg';

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '400px'
}
const slideImages = [
  {
    url: img1,
  },
  {
    url: img2,
  },
  {
    url: img3,
  },
  {
    url: img4,
  },
  {
    url: img5,
  },
  {
    url: img7,
  },
  {
    url: img8,
  },
  {
    url: img9,
  },
  {
    url: img10,
  },
  {
    url: img11,
  },
  {
    url: img12,
  },
  {
    url: img13,
  },
  {
    url: img14,
  },
  {
    url: img15,
  },
  {
    url: img16,
  },
  {
    url: img17,
  },
  {
    url: img18,
  },
  {
    url: img19,
  },
  {
    url: img20,
  },
  {
    url: img21,
  },
  {
    url: img22,
  },
  {
    url: img23,
  },
  {
    url: img24,
  },
  {
    url: img25,
  },
  {
    url: img26,
  },
  {
    url: img27,
  },
  {
    url: img28,
  },
  {
    url: img29,
  },
  {
    url: img30,
  },
  {
    url: img31,
  },
  {
    url: img32,
  },
];

class Gift extends Component {
    render() {
      return (
        <div className="slide-container">
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div className="img-carousel-container" style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
              </div>
            </div>
          ))} 
        </Slide>
      </div>
      );
    }
  }

export default Gift;