import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'


class Gift extends Component {
    render() {
      return (
          <div className="gift-area" id="gift-area">
              <div className="container">
                    <div className="col-12">
                      <div className="section-title text-center">
                          <h2>Moltes gràcies!</h2>
                          <p className="gift-area-p-1">Tenim moltes ganes que arribi el gran dia i poder compartir amb tots i totes vosaltres aquests moments de felicitat.<br></br> Us estimem molt i sou imprescindibles en les nostres vides!</p>
                          <p className="gift-area-p-2">Si ens voleu fer un regal o aportació us estarem molt agraïts!</p>
                          <p className="gift-area-p-2-compte">ES90 2100 1425 1702 0016 4556</p>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
  }

export default Gift;