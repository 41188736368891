import React from "react";
import Header from '../Header';

export default function Navbar() {
const className = "fixed-navbar animated fadeInDown active";

  return (
    <div className={className}>
        <Header />
    </div>
  ); 
}