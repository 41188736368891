import React from 'react'
import Sectiontitle from '../section-title'
import './style.css'

const Location = () => {
    return(

        
    <div id="event" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={'El gran dia'}/>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img className="ceromony-img" src='https://www.cubicpromote.com.au/media/magefan_blog/images/mrbean-calendar.gif' alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content ceromony-content2">
                            <h3>Dia i hora</h3>
                            <p>Celebrarem el casament el dissabte 4 de maig del 2024. <br></br>Serà una celebració de tarda que s'allargarà fins a altes hores de la matinada.<br></br>Us recomanem que vingueu amb les piles al <strong>màxim</strong> per donar-ho tot!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img className="ceromony-img" src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbmF5MnphcnA3YXk3NW5nZ2U3eTY0dGF6Mmg3MW9tajVkYnVsOWdlYSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/42CiLf6v2kENGHi6V0/giphy.gif' alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content">
                            <h3>Horari</h3>
                            <p>
                            <strong>16h.</strong> Arribada dels convidats que es queden a dormir i s'han d'instal·lar a les habitacions.<br></br>
                            <strong>16:30h.</strong> Hora oficial de convocatòria per tots els convidats + BENVINGUDA!<br></br>
                            <strong>17:30h.</strong> Inici de la cerimònia.<br></br>
                            <strong>19h.</strong> Sopar de coctail.<br></br>
                            <strong>22h.</strong> FESTA!<br></br>
                            <br></br>
                            *L'hora d'inici del sopar i la festa són aproximats.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <a href="https://maps.app.goo.gl/vL5HdKrXr6h6GeEHA" target="_blank" rel="noopener noreferrer">
                                    <img className="ceromony-img" src="https://www.masmuxach.com/imgs/foto.jpg" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>Ubicació</h3>
                                <p>La cerimònia, el convit i la festa les durem a terme al MAS MUXACH. <br></br>És una casa rural situada al municipi de Brunyola (Girona).<br></br><strong>SI FEU CLICK A LA FOTO SE US OBRIRÀ LA UBICACIÓ AMB EL GOOGLE MAPS.</strong> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img className="ceromony-img" src='https://www.masmuxach.com/imgs/galeries/interiors//10.jpg' alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content">
                            <h3>On dormir</h3>
                            <p>La casa disposa de llits perquè tothom qui vulgui pugui quedar-se a dormir.<br></br> Es tracta d'habitacions compartides amb lliteres, ja que és una casa rural.<br></br> D'aquesta manera podrem allargar la festa tant com vulguem i no caldrà que ningú es procupi d'agafar el cotxe. Els més ben aventurats ni tocaran el llit.<br></br>Hi haurà nòrdics per tothom, no cal que us preocupeu de dur sac de dormir.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img className="ceromony-img" src='https://m.media-amazon.com/images/M/MV5BMTQxMzE3Mjg4MV5BMl5BanBnXkFtZTgwNDMwNjMwMjE@._V1_.jpg' alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content">
                            <h3>La ressaca</h3>
                            <p>L'endemà al matí, qui hagi dormit a la casa podrà gaudir d'un bon esmorzar per intentar recuperar els cinc sentits.<br></br>Durant el matí de diumenge disposarem de piscina, pista multi esportiva i una half pipe d'skate.<br></br> També tenim l'opció de quedar-nos a dinar i poder allargar una mica més la festa. El menú són 25€ per persona que haurà de pagar cadascú de la seva butxaca.<br></br>El menú constarà d'amanida catalana, rissoto/fideuà i postres variats. Hi ha opcions per a totes les restriccions alimentàries.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img className="ceromony-img" src='https://media.tenor.com/VWlHE5LJ98kAAAAC/suit-up.gif' alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content">
                            <h3>Dress code</h3>
                            <p>Cadascú pot venir vestit com se senti més còmode, però ben elegant!<br></br>Recomanem NO portar talons d'agulla, ja que el terra és de gespa natural.<br></br>A més, com és costum en els casaments, millor que no porteu un vestit blanc. Deixem aquest color a la núvia!<br></br>Ja que estarem enmig de la muntanya, és possible que al vespre refresqui. No us oblideu de portar una jaqueta!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Location;